/* eslint-disable react/no-danger */
import React from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import {lightGray, superLightGray} from '../modules/colors';
import MenuItem from '../components/MenuItem';
import {media} from '../modules/util';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostHeader = styled.div`
  background-color: ${superLightGray};
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  text-align: center;
  max-width: 500px;
  margin: 10px 0;

  ${media.tablet`
    max-width: 100%;
    padding: 0 20px;
  `}
`;

const Date = styled.div`
  color: ${lightGray};
`;

const ShareButtons = styled.div``;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;

  ${media.tablet`padding: 0 20px;`}

  > div {
    width: 980px;

    ${media.tablet`
      width: 100%;
      padding: 0 20px;
    `}
  }

  p {
    margin-bottom: 25px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const Template = ({
  data // this prop will be injected by the GraphQL query below.
}) => {
  const {markdownRemark} = data; // data.markdownRemark holds our post data
  const {frontmatter, html} = markdownRemark;
  const {category, title, date} = frontmatter;
  return (
    <Layout>
      <SEO title="Home" keywords={['really', 'celebrity', 'news']} />
      <Container>
        <PostHeader>
          <MenuItem link={`/category/${category.toLowerCase()}`}>
            {category.toUpperCase()}
          </MenuItem>
          <Title>
            {title}
          </Title>
          <Date>
            {date}
          </Date>
          <ShareButtons>
            {' '}
          </ShareButtons>
        </PostHeader>
        <Content>
          <div dangerouslySetInnerHTML={{__html: html}} />
        </Content>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        category
      }
    }
  }
`;

Template.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired
};

export default Template;
